<template>
  <BT-Blade-Item
    bladeName="dispatch-release"
    :canDelete="false"
    :changeToggle="changeToggle"
    navigation="dispatch-releases"
    title="Dispatch Release">
    <template v-slot="{ item }">
      <BT-Snack v-model="msg" />

      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Name</v-list-item-subtitle>
          <v-list-item-title>{{ item.bundleName || item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-row>
        <v-col cols="6">
          <BT-Field-Address
            v-model="item.departureLocation"
            label="Departure From" />
        </v-col>
        <v-col cols="6">
          <BT-Field-Address
            v-model="item.destinationLocation"
            label="Destination" />
        </v-col>
      </v-row>

      <BT-Field-Date
        v-model="item.dueDepartureOn"
        label="Release Due On"
        shortDateAndTime />

      <BT-Field-Date
        v-if="item.releasedOn != null"
        v-model="item.releasedOn"
        label="Released On"
        shortDateAndTime />
      
      <BT-Field-Object
        v-model="item.courier"
        itemText="companyName"
        label="Courier" />

      <v-row>
        <!-- <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelV">
            <BT-Blade-Expansion-Items
              addBladeName="customer-consignment"
              :canExportCSV="false"
              :canRefresh="false"
              customURL="/get/GetConsignmentIDs"
              :headers="[
                { text: 'CO#', value: 'consignmentNumber', subtitle: 1, prefix: 'CO# ' },
                { text: 'Customer', value: 'buyer.companyName', title: 1 }]"
              hideActions
              navigation="deliveries"
              :onPullSuccessAsync="pullConsignments"
              :params="{ id: item.id }"
              showTable
              title="Consignments" />
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelVV">
            <BT-Blade-Expansion-Items
              :canExportCSV="false"
              :canRefresh="false"
              :canSelect="false"
              :headers="[
                { text: '', value: 'action', display: true, isIcon: true, width: 10 },
                { text: 'Released/Packed', value: 'qty', display: true, prefix: 'Rlsd/Pkd', subtitle: 1 },
                { text: 'Package', value: 'measurement', display: true, title: 1 }]"
              hideActions
              :items="item.packagedItems"
              showTable
              title="Packages">
              <template v-slot:action="{ item }">
                <v-btn
                  @click="item.quantityReleased = item.quantityPacked"
                  icon
                  small>
                  <v-icon :class="item.quantityReleased == item.quantityPacked ? 'success--text' : 'warning--text'" small>mdi-check</v-icon>
                </v-btn>
              </template>
              <template v-slot:actions="{ open }">
                <BT-Packages-Movement-Dialog
                    v-if="open && item.releasedOn == null"
                    v-model="item.packagedItems"
                    icon-only
                    packed
                    released
                    small
                    @ok="packages => { item.packagedItems = packages }" />
              </template>
              <template v-slot:qty="{ item }">
                <v-edit-dialog>
                  {{ item.quantityReleased | toDisplayNumber }} / {{ item.quantityPacked | toDisplayNumber }}
                  <template v-slot:input>
                    <BT-Number-Edit
                      v-model="item.quantityReleased"
                      label="Released" />
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:measurement="{ item }">
                <BT-Entity 
                  navigation="measurements"
                  ignorePermissions
                  :itemValue="item.measurementID"
                  itemText="measurementName" />
              </template>
            </BT-Blade-Expansion-Items>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelIV">
            <v-expansion-panel>
              <v-expansion-panel-header>Info</v-expansion-panel-header>
              <v-expansion-panel-content>
                <BT-Field-Checkbox
                  v-model="item.isAutoDeliver"
                  label="Auto Deliver"
                  :isEditing="data.isEditing" />

                <v-list-item v-if="item.isPickup" dense>
                  <v-list-item-avatar>
                    <v-icon small>mdi-check</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Pickup</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.isFulfilled" dense>
                  <v-list-item-avatar>
                    <v-icon small>mdi-check</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Fulfilled</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.releasedOn != null" dense>
                  <v-list-item-avatar>
                    <v-icon small>mdi-check</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Released</v-list-item-title>
                    <v-list-item-subtitle>{{ item.releasedOn | toShortDateAndTime }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <BT-Entity
                        v-if="$canView('users')"
                        navigation="users"
                        :itemValue="item.releasedByUserID"
                        itemText="userName" />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelVI">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Signature
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BT-Signature
                  v-if="item.releasedOn != null"
                  :value="item.proofOfRelease"
                  color="white"
                  height="80%" />
                <BT-Signature-Overlay
                  v-else
                  v-model="item.proofOfRelease"
                  buttonClass="primary"
                  color="white"
                  fullscreen
                  icon=""
                  @ok="por => { saveRelease(item, por) }"
                  text="Sign Release"
                  transparent />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col> -->
      </v-row>
    </template>
  </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Dispatch-Release',
    components: {
      // BTPackagesMovementDialog: () => import('~components/BT-Packages-Movement-Dialog.vue'),
      // BTSignatureOverlay: () => import("~components/BT-Signature-Overlay.vue"),
      // BTSignature: () => import('~components/BT-Signature.vue')
    },
    data: function() {
      return {
        changeToggle: false,
        consignmentIDs: null,
        msg: null,
        panelV: 0,
        panelVV: 0,
        panelIV: 0,
        panelVI: 0
      }
    },
    methods: {
      async pullConsignments(items, refresh) {
        var excessIDs = [];
        //var iter = 0;

        this.consignmentIDs = items.flatMap(x => x.value);

        excessIDs = this.consignmentIDs;
        //iter = 0;

        var results = [];

        try {
            if (this.isLengthyArray(excessIDs)) {
              //pull in sets of 20
              do {
                  var conIDs = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                  //iter += conIDs.length;
                  //this.loadingMsg = `${iter} of ${this.allConsignmentIDs.length} courier consignments`;
                  var cResults = this.copyDeep(await this.$BlitzIt.store.getAll('customer-consignments', { ids: conIDs.toString(), includeDetails: false, properties: 'ID,Buyer,ConsignmentNumber' }, refresh));
                  console.log(cResults);
                  cResults.forEach(consignment => {
                      //this.addOrUpdateCourierConsignment(consignment);
                      results.push(consignment);
                  })

                  excessIDs = excessIDs.filter(x => !conIDs.some(y => y == x));
              } while (this.isLengthyArray(excessIDs))
            }
          }
          catch (err) {
            this.msg = this.extractErrorDescription(err);
          }
          finally {
            this.loadingMsg = null;
          }

          results = results.map(x => Object.assign({}, x, { loadingCount: 0, errorMsg: null }));

          return results;
      },
      async saveRelease(release, proofOfRelease) {
        if (proofOfRelease == null) {
          this.msg = 'No signature provided';
          return;
        }

        try {
          this.msg = 'Saving Release';
          release.proofOfRelease = proofOfRelease;
          var res = await this.$BlitzIt.store.patch('releases', release);
          release.rowVersion = res.rowVersion;
          release.releasedOn = res.releasedOn;
          release.releasedByUserID = res.releasedByUserID;
          this.changeToggle = !this.changeToggle;
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      },
    }
}
</script>